<template>
  <div>
    <h1>Assessment and salary increase</h1>
    <hr />
    <div class="page-header">
      <div class="upload">
        <button class="button primary" @click="$refs.excelFile.click()">
          Upload Excel
        </button>
        <input type="file" ref="excelFile" @change="UploadExcel" />
      </div>

      <button class="button grey mg-left" @click="clean">Clean</button>

      <div class="header-end">
        <button
          class="button primary"
          style="margin-right: 10px"
          @click="isStop = true"
          title="One Click Only"
        >
          Stop
        </button>
        <button class="button danger" @click="sendAll()" title="One Click Only">
          Send All
        </button>
      </div>
    </div>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>E-mail</th>
          <th>MID-POINT</th>
          <th>Salary Increase</th>
          <th>Net Salary</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in a" :key="idx">
          <td>{{ i.ID }}</td>
          <td>{{ i.name }}</td>
          <td>{{ i.email }}</td>
          <td>
            {{
              new Intl.NumberFormat("LAK", {
                style: "currency",
                currency: "LAK",
              }).format(i.basicSalary)
            }}
          </td>
          <td>
            {{
              new Intl.NumberFormat("LAK", {
                style: "currency",
                currency: "LAK",
              }).format(i.salaryIncrease)
            }}
          </td>
          <td>
            {{
              new Intl.NumberFormat("LAK", {
                style: "currency",
                currency: "LAK",
              }).format(i.netSalary)
            }}
          </td>
          <td>
            <button
              class="button danger"
              @click="send(i)"
              title="If status true, Please do not click again"
              :class="i.sent ? 'primary' : 'danger'"
            >
              {{ i.sent }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <LoadingScreen v-if="isloading" />
  </div>
</template>

<script>
import XLSX from "xlsx";
import LoadingScreen from "./LoadingScreen";

export default {
  components: {
    LoadingScreen,
  },
  data: () => ({
    a: [],
    isloading: false,
    isStop: false,
  }),
  methods: {
    async UploadExcel($file) {
      const file = $file.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        workbook.SheetNames.map(async (sheetName) => {
          const xlRowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          const jsonObject = JSON.stringify(xlRowObject);
          const parseJson = JSON.parse(jsonObject);
          const mapper = parseJson.map((obj) => {
            const k = {
              ID: obj["ID"],
              name: obj["Name"],
              email: obj["Email"],
              position: obj["Position"],
              work: obj["work"],
              level: obj["Level"],
              grade1: obj["grade1"],
              A: [],
              basicSalary: obj["MID-POINT"],
              salaryIncrease: obj["salaryIncrease"],
              currently: obj["currently_salary"],
              feature: obj["Feature_Salary"],
              positionSalary: obj["Position_salary"],
              POF: obj["Provincial operation fee"],
              netSalary: obj["NetSalary"],
              sent: false,
            };
            for (const key in obj) {
              //  console.log(`${key}: ${obj[key]}`)
              const first = key.split(" - ")[0];
              const last = key.split(" - ")[1];

              switch (first) {
                case "A": {
                  if (obj[key] === 0) break;
                  k.A.push({
                    name: last,
                    amount: obj[key],
                  });
                  break;
                }
              }
            }
            return k;
          });
          //  console.log(mapper);
          this.excel = mapper;
          localStorage.setItem("session", JSON.stringify(this.excel));
          this.a = JSON.parse(localStorage.getItem("session")) || [];
        });
      };
      reader.onerror = (ex) => {
        alert(ex);
      };
      reader.readAsBinaryString(file);
      // this.$refs.FileInput.value = null;
    },
    // send mail
    async sendAll() {
      const filter = this.a.filter((i) => !i.sent);
      for (let i = 0; i < filter.length; i++) {
        if (this.isStop) return;
        const obj = filter[i];
        await new Promise((resolve) =>
          setTimeout(async () => {
            await this.send(obj);
            resolve("succeed");
          }, 1000)
        );
      }
    },
    async send(i) {
      await this.$Axios.post(this.URL + `send`, {
        data: i,
      });
      const idx = this.a.findIndex((o) => o.ID === i.ID);
      this.a[idx].sent = true;
      localStorage.setItem("session", JSON.stringify(this.a));
    },
    clean() {
      this.a = [];
      localStorage.removeItem("session");
    },
  },
  created() {
    this.a = JSON.parse(localStorage.getItem("session")) || [];
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  .upload {
    input {
      display: none;
    }
  }
  .page-title {
    font-size: 30px;
    font-weight: 700;
    margin-right: 10px;
  }
  .header-end {
    margin-left: auto;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: Saysettha ot;
  th {
    padding: 5px 0;
    &.tb-xs {
      width: 10%;
    }
  }
  td {
    padding: 5px 0;
    border-top: 1px solid $border-color;
    font-size: 16px;
  }
}

button.button {
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  border: none;
  height: 2.5em;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
  &.mg-left {
    margin-left: 10px;
  }
  &.danger {
    background-color: red;
    color: #fff;
  }
  &.grey {
    background-color: $grey-color;
    color: $font-color;
  }
  &.primary {
    background-color: $primary-color;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}
</style>
